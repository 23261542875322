<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>

    <!-- Projects Table -->
    <a-row :gutter="24">

      <!-- Projects Table Column -->
      <a-col :span="24" class="mb-24">

        <!-- Projects Table Column -->
        <CardAuthorTable
            :data="Data"
            :columns="Columns"
        ></CardAuthorTable>
        <!-- / Projects Table Column -->

      </a-col>
      <!-- / Projects Table Column -->

    </a-row>
    <!-- / Projects Table -->

  </div>
</template>

<script>

import CardAuthorTable from "@/components/Cards/CardAuthorTable";
// "Projects" table component.
import {getLoans, getRepays} from "@/api/system/client";


// "Projects" table list of columns and their properties.
const Columns = [
  {
    title: '还款期数',
    dataIndex: 'repayNumber',
    scopedSlots: { customRender: 'repayNumber' },
    width: 120,
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.repayNumber - b.repayNumber,
  },
  {
    title: '还款金额',
    dataIndex: 'repayTotal',
    scopedSlots: { customRender: 'repayTotal' },
    class: 'font-semibold text-muted',
    width: 150,
  },
  {
    title: '还款本金',
    dataIndex: 'repayMoney',
    scopedSlots: { customRender: 'repayMoney' },
    class: 'font-semibold text-muted',
    width: 150,
  },
  {
    title: '还款利息',
    dataIndex: 'repayInterest',
    scopedSlots: { customRender: 'repayInterest' },
    class: 'font-semibold text-muted',
    width: 150,
  },
  {
    title: '还款日期',
    dataIndex: 'repayDate',
    scopedSlots: { customRender: 'repayDate' },
    width: 150,
  },
  {
    title: '还款状态',
    dataIndex: 'repayStatus',
    scopedSlots: { customRender: 'repayStatus' },
    class: 'font-semibold text-muted',
    width: 150,
  },
];

// "Projects" table list of rows and their properties.
const Data = [
  {
    key: '1',
    company: {
      name: 'Spotify Version',
      logo: 'images/logos/logo-spotify.svg',
    },
    status: "working",
    budget: '$14,000',
    completion: 60,
  },
  {
    key: '2',
    company: {
      name: 'Progress Track',
      logo: 'images/logos/logo-atlassian.svg',
    },
    status: "working",
    budget: '$3,000',
    completion: 10,
  },
  {
    key: '3',
    company: {
      name: 'Jira Platform Errors',
      logo: 'images/logos/logo-slack.svg',
    },
    status: "done",
    budget: 'Not Set',
    completion: {
      status: 'success',
      value: 100,
    },
  },
  {
    key: '4',
    company: {
      name: 'Launch new Mobile App',
      logo: 'images/logos/logo-spotify.svg',
    },
    status: "canceled",
    budget: '$20,600',
    completion: {
      status: 'exception',
      value: 50,
    },
  },
  {
    key: '5',
    company: {
      name: 'Web Dev',
      logo: 'images/logos/logo-webdev.svg',
    },
    status: "working",
    budget: '$4,000',
    completion: 80,
  },
  {
    key: '6',
    company: {
      name: 'Redesign Online Store',
      logo: 'images/logos/logo-invision.svg',
    },
    status: "canceled",
    budget: '$2,000',
    completion: {
      status: 'exception',
      value: 0,
    },
  },
];

export default ({
  components: {
    CardAuthorTable,
  },
  data() {
    return {

      // Associating "Projects" table data with its corresponding property.
      Data: Data,

      // Associating "Projects" table columns with its corresponding property.
      Columns: Columns,
      //
      loanId: undefined,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loanId = this.$route.query.loanId;
      if(this.loanId === undefined) {
        this.$message.error("发生错误,返回主页");
        this.$router.push({path: '/'});
        return;
      }
      getRepays(this.loanId).then(response => {
        this.Data = response.data;
      });
    }
  },
})

</script>

<style lang="scss">
</style>