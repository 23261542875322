<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">还款台账</h5>
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :rowKey="(record, index) => { return index }">

      <template slot="text" slot-scope="text">
        <a style="color: #1F1F1F">{{ text }}</a>
      </template>

      <template slot="repayNumber" slot-scope="repayNumber">
        <a v-if="repayNumber === 0" style="color: #1F1F1F">提前还款</a>
        <a v-else style="color: #1F1F1F">{{ repayNumber }}</a>
      </template>

      <template slot="repayTotal" slot-scope="repayTotal">
        <a style="color: #1F1F1F">{{ moneyFormat(repayTotal) }}元</a>
      </template>

      <template slot="repayMoney" slot-scope="repayMoney">
        <a style="color: #1F1F1F">{{ moneyFormat(repayMoney) }}元</a>
      </template>

      <template slot="repayInterest" slot-scope="repayInterest">
        <a style="color: #1F1F1F">{{ moneyFormat(repayInterest) }}元</a>
      </template>

      <template slot="repayDate" slot-scope="repayDate">
        <a style="color: #1F1F1F">{{ parseTime(repayDate, '{y}-{m}-{d}') }}</a>
      </template>

      <template slot="repayStatus" slot-scope="repayStatus">
        <a-tag v-if="repayStatus==='0'">未还款</a-tag>
        <a-tag v-else-if="repayStatus==='1'" color="green">已还款</a-tag>
        <a-tag v-else color="red">逾期</a-tag>
      </template>

		</a-table>
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>

	import {moneyFormat} from "@/utils/amountFormat";
  import {parseTime} from "@/utils/ruoyi";

  export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
			}
		},
    methods: {
      moneyFormat,
      parseTime,
    },
	})

</script>